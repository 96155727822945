var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c(
                  "c-table",
                  {
                    ref: "table",
                    attrs: {
                      title: "일상점검 목록",
                      merge: _vm.grid.merge,
                      gridHeight: "700px",
                      columns: _vm.grid.columns,
                      data: _vm.grid.data,
                      editable: !_vm.disabled,
                      filtering: false,
                      columnSetting: false,
                      usePaging: false,
                      selection: _vm.popupParam.data.dailyCheckId
                        ? "multiple"
                        : "none",
                      rowKey: "dailyCheckResultId",
                    },
                    on: { headerDataChange: _vm.headerDataChange },
                    scopedSlots: _vm._u([
                      {
                        key: "customArea",
                        fn: function ({ props, col }) {
                          return [
                            col.name === "cycle1"
                              ? [
                                  props.row["cycle1"] === "Y"
                                    ? _c("span", [
                                        _vm._v(" " + _vm._s("●") + " "),
                                      ])
                                    : _vm._e(),
                                ]
                              : col.name === "cycle2"
                              ? [
                                  props.row["cycle2"] === "Y"
                                    ? _c("span", [
                                        _vm._v(" " + _vm._s("●") + " "),
                                      ])
                                    : _vm._e(),
                                ]
                              : col.name === "cycle3"
                              ? [
                                  props.row["cycle3"] === "Y"
                                    ? _c("span", [
                                        _vm._v(" " + _vm._s("●") + " "),
                                      ])
                                    : _vm._e(),
                                ]
                              : props.row["cbmFlag"] === "N"
                              ? void 0
                              : col.name === "gaugeWarn"
                              ? [
                                  _c(
                                    "font",
                                    {
                                      staticStyle: {
                                        "font-size": "1.0em",
                                        "font-weight": "700",
                                      },
                                      attrs: { color: "#0300c1" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(props.row.gaugeWarnLcl) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "font",
                                    {
                                      staticStyle: {
                                        "font-size": "1.0em",
                                        "font-weight": "700",
                                      },
                                    },
                                    [_vm._v(" ~ ")]
                                  ),
                                  _c(
                                    "font",
                                    {
                                      staticStyle: {
                                        "font-size": "1.0em",
                                        "font-weight": "700",
                                      },
                                      attrs: { color: "#C10015" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(props.row.gaugeWarnUcl) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              : col.name === "gaugeExec"
                              ? [
                                  _c(
                                    "font",
                                    {
                                      staticStyle: {
                                        "font-size": "1.0em",
                                        "font-weight": "700",
                                      },
                                      attrs: { color: "#0300c1" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(props.row.gaugeExecLcl) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "font",
                                    {
                                      staticStyle: {
                                        "font-size": "1.0em",
                                        "font-weight": "700",
                                      },
                                    },
                                    [_vm._v(" ~ ")]
                                  ),
                                  _c(
                                    "font",
                                    {
                                      staticStyle: {
                                        "font-size": "1.0em",
                                        "font-weight": "700",
                                      },
                                      attrs: { color: "#C10015" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(props.row.gaugeExecUcl) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              : col.name === "cbmAlert"
                              ? [
                                  _c(
                                    "q-chip",
                                    {
                                      class: null,
                                      attrs: {
                                        color: _vm.setTagColor(props.row),
                                        clickable: false,
                                        "text-color": "white",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.setTagName(props.row)) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              : col.name === _vm.popupParam.col.key + "C"
                              ? [
                                  _c("c-number-column", {
                                    attrs: {
                                      editable: _vm.editable,
                                      col: col,
                                      props: props,
                                    },
                                    on: {
                                      datachange: function ($event) {
                                        return _vm.datachange(props, col)
                                      },
                                    },
                                    model: {
                                      value:
                                        props.row[_vm.popupParam.col.key + "C"],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          props.row,
                                          _vm.popupParam.col.key + "C",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "props.row[popupParam.col.key + 'C']",
                                    },
                                  }),
                                ]
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c(
                      "template",
                      { slot: "suffixTitle" },
                      [
                        !_vm.popupParam.data.lineId &&
                        !_vm.popupParam.data.checkDate
                          ? _c("font", { attrs: { color: "#C10015" } }, [
                              _vm._v(" ※ 기능위치와 점검년월을 선택하세요. "),
                            ])
                          : _vm._e(),
                        _c(
                          "font",
                          {
                            staticStyle: {
                              "font-size": "0.8em",
                              "font-weight": "300",
                            },
                          },
                          [
                            _c("q-icon", {
                              staticClass: "text-black",
                              attrs: { name: "radio_button_unchecked" },
                            }),
                            _vm._v(" : 정상    "),
                            _c("q-icon", {
                              staticClass: "text-black",
                              attrs: { name: "close" },
                            }),
                            _vm._v(" : 이상    "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "template",
                      { slot: "table-button" },
                      [
                        _c(
                          "q-btn-group",
                          [
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: { label: "정비요청", icon: "add" },
                                  on: { btnClicked: _vm.linkClick },
                                })
                              : _vm._e(),
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.saveUrl,
                                    isSubmit: _vm.isSave,
                                    param: _vm.popupParam.data.dailyCheckId
                                      ? _vm.popupParam.data.dailyResultModelList
                                      : this.popupParam.data,
                                    mappingType: "POST",
                                    label: "저장",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveData,
                                    btnCallback: _vm.saveCallback,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
          _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }