<template>
  <div>
     <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-table
            ref="table"
            title= "일상점검 목록"
            :merge="grid.merge"
            gridHeight="700px"
            :columns="grid.columns"
            :data="grid.data"
            :editable="!disabled"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            :selection="popupParam.data.dailyCheckId ? 'multiple': 'none'"
            rowKey="dailyCheckResultId"
            @headerDataChange="headerDataChange"
          >
            <template slot="suffixTitle">
              <font v-if="!popupParam.data.lineId && !popupParam.data.checkDate" color="#C10015">
                ※ 기능위치와 점검년월을 선택하세요.
              </font>
              <font style="font-size:0.8em;font-weight:300;">
                <q-icon name="radio_button_unchecked" class="text-black"/> : 정상 &nbsp;&nbsp;
                <q-icon name="close" class="text-black"/> : 이상 &nbsp;&nbsp;
              </font>
            </template>
            <!-- 버튼 영역 -->
            <template v-slot:customArea="{ props, col }">
              <template v-if="col.name === 'cycle1'">
                <span v-if="props.row['cycle1'] === 'Y'">
                  {{ '●' }}
                </span>
              </template>
              <template v-else-if="col.name === 'cycle2'">
                <span v-if="props.row['cycle2'] === 'Y'">
                  {{ '●' }}
                </span>
              </template>
              <template v-else-if="col.name === 'cycle3'">
                <span v-if="props.row['cycle3'] === 'Y'">
                  {{ '●' }}
                </span>
              </template>
              <template v-else-if="props.row['cbmFlag'] === 'N'">
              </template>
              <template v-else-if="col.name === 'gaugeWarn'">
                <font style="font-size:1.0em;font-weight:700;" color="#0300c1">
                  {{ props.row.gaugeWarnLcl}}
                </font>
                <font style="font-size:1.0em;font-weight:700;">
                  ~
                </font>
                <font style="font-size:1.0em;font-weight:700;" color="#C10015">
                  {{ props.row.gaugeWarnUcl}}
                </font>
              </template>
              <template v-else-if="col.name === 'gaugeExec'">
                <font style="font-size:1.0em;font-weight:700;" color="#0300c1">
                  {{ props.row.gaugeExecLcl}}
                </font>
                <font style="font-size:1.0em;font-weight:700;">
                  ~
                </font>
                <font style="font-size:1.0em;font-weight:700;" color="#C10015">
                  {{ props.row.gaugeExecUcl}}
                </font>
              </template>
              <template v-else-if="col.name === 'cbmAlert'">
                <q-chip
                  :color="setTagColor(props.row)"
                  :clickable="false"
                  :class="null"
                  text-color="white">
                  {{setTagName(props.row)}}
                </q-chip>
              </template>
              <template v-else-if="col.name === popupParam.col.key + 'C'">
                <c-number-column
                  :editable="editable"
                  :col="col"
                  :props="props"
                  v-model="props.row[popupParam.col.key + 'C']"
                  @datachange="datachange(props, col)"
                />
              </template>
            </template>
            <template slot="table-button">
              <q-btn-group>
                <c-btn label="정비요청" v-if="editable" icon="add" @btnClicked="linkClick" />
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="popupParam.data.dailyCheckId ? popupParam.data.dailyResultModelList : this.popupParam.data"
                  mappingType="POST"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
          </c-table>
        </div>  
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-daily-checking',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        col: {},
        equipmentList: [],
        data: {
          dailyCheckId: '',  // 열화점검 일련 번호
          plantCd: '',  // 사업장 코드
          checkStatusCd: '',  // 계획수립/점검중/점검완료
          lineId: '',  // 라인일련번호
          checkDeptCd: '',  // 점검부서
          checkUserId: '',  // 점검자 ID
          checkDate: '',  // 점검월
          deteriorName: '',  // 점검명
          dailyResultModelList: [], // sheet
        }
      }),
    },
    returnData: {
      type: Object,
      default: () => ({
        col1: null,
        col2: null,
        col3: null,
      }),
    }
  },
  data() {
    return {
      year: '',
      grid: {
        merge: [
          { index: 0, colName: 'equipmentName' },
          { index: 1, colName: 'checkItemPartName' },
        ],
        columns: [],
        data: [],
      },
      // grid2: {
      //   merge: [
      //     { index: 0, colName: 'equipmentName' },
      //     { index: 1, colName: 'checkItemPartName' },
      //   ],
      //   columns: [],
      //   data: [],
      // },
      popupOptions: {
        isFull: true,
        // width: '80%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      editable: true,
      saveUrl: transactionConfig.sop.min.equipment.plan.daily.insert.url,
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      saveUrl2: transactionConfig.sop.min.equipment.plan.daily.insert.url,
      isSave2: false,
      completeUrl: '',
      isSave: false,
      isComplete: false,
      mappingType: 'POST',
      mappingType2: 'POST',
      getUrl: '',
      resultItemDetail: '',
      lunarHolidays: [],
      today: '',
      lineCd: '',
      colorItems: [
        { name: '경고', color: 'red' },
        { name: '정비요청필요', color: 'orange' },
        { name: '정상', color: 'blue' },
      ],
      checkboxItems: [
        // { code: null, codeName: ''},
        { code: 'CHK001', codeName: 'o'},
        { code: 'CHK002', codeName: 'X'},
        // { code: 'CHK003', codeName: '●'},
        // { code: 'CHK004', codeName: 'V'},
        // { code: 'CHK005', codeName: '□'},
        // { code: 'CHK006', codeName: '△'},
        // { code: 'CHK007', codeName: '▲'},
        // { code: 'CHK008', codeName: '⊙'},
      ],
      equipList: [],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
     return this.popupParam.data.checkStatusCd === 'MCSC000015' || Boolean(this.popupParam.data.sysApprovalRequestId)
    },
    // 테이블 키 멀티
    tableKeys() {
      return ['dailyCheckResultId','colkey'];
    },
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['equipmentName', 'checkItemPartName', 'checkItemName',];
    },
    // 개선요청 Component
    imprComponent() {
      let components = () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
      return components;
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting

      this.getUrl = selectConfig.sop.min.equipment.result.daily.get.url;
      this.insertUrl = transactionConfig.sop.min.equipment.plan.daily.insert.url;
      this.resultItemDetail = selectConfig.sop.min.equipment.result.daily.newItem.url;
      this.updateUrl = transactionConfig.sop.min.equipment.result.daily.newItem.url;
      // code setting
      this.equipList = this.$_.map(this.popupParam.equipmentList, 'equipmentCd')
      this.equipList = this.equipList.filter((element, index) => {
          return this.equipList.indexOf(element) === index;
      });
      // list setting
      this.research();
    },
    research() {
      if (this.popupParam.data.dailyCheckId) {
        this.$http.url = this.getUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          dailyCheckId: this.popupParam.data.dailyCheckId,
          equipList: this.equipList,
          colKey: this.popupParam.col.key,
        }
        this.$http.request((_result) => {
          this.grid.data = _result.data

          this.$_.forEach(this.grid.data, _item => {
            _item.colkey = this.popupParam.col.key;
          })
          this.setHeader();
        },);
      }
    },
    setHeader() {
      this.grid.columns = [
        {
          name: 'equipmentName',
          field: 'equipmentName',
          label: '설비명',
          align: 'center',
          style: 'width:180px',
          type: 'html',
          sortable: false,
        },
        {
          name: 'checkItemPartName',
          field: 'checkItemPartName',
          label: '부품명',
          align: 'center',
          style: 'width:150px',
          sortable: false,
        },
        {
          name: 'checkItemName',
          field: 'checkItemName',
          label: '점검항목',
          align: 'left',
          style: 'width:150px',
          sortable: false,
        },
        {
          name: 'checkItemMethod',
          field: 'checkItemMethod',
          label: '점검방법',
          align: 'left',
          style: 'width:150px',
          sortable: false,
        },
        {
          name: 'checkStandard',
          field: 'checkStandard',
          label: '점검기준',
          align: 'left',
          style: 'width:150px',
          sortable: false,
        },
        {
          required: true,
          name: this.popupParam.col.key,
          field: this.popupParam.col.key,
          label: '점검결과',
          align: 'center',
          comboItems: this.checkboxItems,
          style: 'width:100px',
          setHeader: true,
          type: 'select',
          sortable: false,
        },
        {
          label: '주기',
          align: 'center',
          sortable: false,
          child: [
            {
              name: 'cycle1',
              field: 'cycle1',
              label: '일',
              style: 'width:30px', 
              align: 'center',
              type: 'custom',
              sortable: false,
            },
            {
              name: 'cycle2',
              field: 'cycle2',
              label: '주',
              style: 'width:30px', 
              align: 'center',
              type: 'custom',
              sortable: false,
            },
            {
              name: 'cycle3',
              field: 'cycle3',
              label: '월',
              style: 'width:30px', 
              align: 'center',
              type: 'custom',
              sortable: false,
            },
          ]
        },
        {
          name: 'cbmTypeName',
          field: 'cbmTypeName',
          label: 'CBM 종류',
          align: 'center',
          style: 'width:100px',
          sortable: false,
        },
        {
          name: 'gaugeWarn',
          field: 'gaugeWarn',
          label: '경고값',
          align: 'center',
          style: 'width:100px',
          sortable: false,
          type: 'custom',
        },
        {
          name: 'gaugeExec',
          field: 'gaugeExec',
          label: '실행값',
          align: 'center',
          style: 'width:100px',
          sortable: false,
          type: 'custom',
        },
        {
          name: this.popupParam.col.key +'C',
          field: this.popupParam.col.key +'C',
          label: '기준값',
          align: 'center',
          type: 'custom',
          style: 'width:80px',
          sortable: false,
        },
        {
          name: 'cbmAlert',
          field: 'cbmAlert',
          label: '수치값 상태',
          align: 'center',
          style: 'width:100px',
          sortable: false,
          type: 'custom',
        },
      ]
    },
    saveData() {
      if (this.popupParam.data.dailyCheckId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.popupParam.data.regUserId = this.$store.getters.user.userId;
            this.popupParam.data.chgUserId = this.$store.getters.user.userId;

            this.popupParam.data.utilityFlag = 'N';
            this.$_.forEach(this.grid.data, _item => {
              _item.regUserId = this.$store.getters.user.userId;
              _item.chgUserId = this.$store.getters.user.userId;
            })
            
            if (this.mappingType === 'POST') {
              this.$_.forEach(this.grid.data, _item => {
                this.$_.forEach(this.popupParam.data.dailyResultModelList, __item => {
                  if(__item.dailyCheckResultId === _item.dailyCheckResultId) {
                    __item = _item;
                  }
                })
              })
            } else if (this.mappingType === 'PUT') {
              this.popupParam.data.dailyResultModelList = this.$_.clone(this.grid.data);
            } 
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.research();
    },
    headerDataChange(props, col) {
      if (col.name === this.popupParam.col.key) {
        this.$_.forEach(this.grid2.data, _item => {
          this.$set(_item, this.popupParam.col.key, null)
        })
      } else if (col.name === this.popupParam.col.key + 'C') {
        this.$_.forEach(this.grid2.data, _item => {
          this.$set(_item, this.popupParam.col.key + 'C', null)
        })
      }
    },
    setTagColor(row) {
      if (Number(row.gaugeExecLcl) >= Number(row[this.popupParam.col.key +'C']) || Number(row.gaugeExecUcl) <= Number(row[this.popupParam.col.key +'C'])) {
        return 'red'
      } else if (Number(row.gaugeWarnLcl) >= Number(row[this.popupParam.col.key +'C']) || Number(row.gaugeWarnUcl) <= Number(row[this.popupParam.col.key +'C'])) {
        return 'orange'
      } else {
        return 'blue'
      }
    },
    setTagName(row) {
      if (Number(row.gaugeExecLcl) >= Number(row[this.popupParam.col.key +'C']) || Number(row.gaugeExecUcl) <= Number(row[this.popupParam.col.key +'C'])) {
        return '정비요청필요'
      } else if (Number(row.gaugeWarnLcl) >= Number(row[this.popupParam.col.key +'C']) || Number(row.gaugeWarnUcl) <= Number(row[this.popupParam.col.key +'C'])) {
        return '경고'
      } else {
        return '정상'
      }
    },
    datachange(props) {
      if (props.row.editFlag !== 'C') {
        props.row.editFlag = 'U'
        props.row.chgUserId = this.$store.getters.user.userId
      }
    },
    innerBtnClicked() {
        this.popupOptions.title = '정비요청';
        this.popupOptions.param = {
          ibmTaskTypeCd: 'ITT0000065',
        };
        this.popupOptions.target = () => import(`${'@/pages/wod/workOrderRequestDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.isFull = false;
        this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    closeAddImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    // 개선창 닫기 후
    imprChange() {
      this.research();
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
    },
    linkClick() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '점검항목을 선택하세요.', // 공기구를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        let row = selectData[0] // 대표설비
        let title = row.equipmentName;
        this.$_.forEach(selectData, _item => {
          title +=  '|' + _item.checkItemPartName + '|' + _item.checkItemName + '☞' + _item.cbmTypeName
           + '[경고값: ' + _item.gaugeWarnLcl + '~' + _item.gaugeWarnUcl + '|실행값: ' + _item.gaugeExecLcl + '~' + _item.gaugeExecUcl +']';
        })
        
        this.popupOptions.title = '정비요청 상세';
        this.popupOptions.param = {
          woRequestId: '',
          plantCd: '',
          dailyCheckId: this.popupParam.data.dailyCheckId,
          equipmentCd: row.equipmentCd,
          remark: title,
          woRequestName: this.popupParam.col.date + ' 일상점검에 대한 정비요청',
          funcLocationCd: row.funcLocationCd,
          costCenterCd: row.costCenterCd,
          woRequestDt: this.popupParam.col.date,
          woWorkTypeCd: 'WWTC000007',
        };
        this.popupOptions.target = () => import(`${'@/pages/wod/workOrderRequestDetail.vue'}`);
        this.popupOptions.visible = true;
        this.popupOptions.isFull = false;
        this.popupOptions.width = '90%';
        this.popupOptions.closeCallback = this.closePopup;
      }

      
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
<style lang="scss">
.tooltip-title {
  width: 100px;
  display: inline-block;
  text-align: center;
  font-weight: 600;
  line-height: 24px;
  border-radius: 5px;
  background: #757474;
  color: #fff;
  margin: 2px;
}
.tooltip-layer {
  background-color: #fff;
  color: #504f4f;
  padding: 5px;
}
.tooltip-content {
  padding-left: 5px;
  display: inline-block;
  line-height: 24px;
  font-weight: 600;
}
.tooltip-date {
  width: 100%;
  display: inline-block;
  line-height: 26px;
  text-align: center;
  font-weight: 600;
  font-size: 1.1em;
}
</style>

